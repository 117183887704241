/* for hiding process is not defined bug from console */
body
  > iframe[style*="2147483647"]:not([id="webpack-dev-server-client-overlay"]) {
  display: none;
}
td {
  word-wrap: break-word;
  white-space: normal;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: "";
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 50vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
  button.btn-first-warning{
    margin-right: 0;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: inherit;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
.dropdown {
  display: none !important;
}

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}
@media (min-width: 1600px) {
  .container {
    max-width: 1600px !important;
  }
}

@media (min-width: 1900px) {
  .container {
    max-width: 1920px !important;
  }
}

/* input[type=text]:disabled {
  background: #F9FAFB
} */

input[disabled]:hover {
  cursor: default;
}

.page-size-selector {
  height: 30px;
  display: flex;
  float: right;
}
.blue-btn {
  background-color: #3f83f8 !important;
}
.red-btn {
  background-color: #f98080 !important;
}
.dpn {
  display: none !important;
}
.error-message {
  color: red;
}
.clickable-info-card {
  cursor: pointer;
}
.red-button {
  background-color: #f05252 !important;
}
@media (max-width: 820px) {
  .items-center img:not(.notification-icon) {
    min-width: 120px;
  }
  img.upload-icon{
    min-width: 10px !important;
  }
}
.notification-icon{
  max-width: none;
}
.title-page {
  font-weight: 700;
  font-size: 40px;
}
.bg-gray {
  background-color: #e3e4e4;
}
.theme-dark .bg-gray {
  background-color: #1a1c23;
}
.footer-table {
  color: #76787d;
  line-height: 21px;
}
.paging-custom ul {
  float: right;
}
.paging-custom ul li button {
  color: #5161f1;
}
.paging-custom ul li button {
  color: #5161f1;
}
.paging-custom ul li .Mui-selected {
  background-color: #5161f1;
  color: white;
}
.paging-custom ul li .Mui-selected:hover {
  color: #5161f1;
}
.paging-custom .MuiPaginationItem-previousNext svg {
  stroke: black;
}
.paging-custom .MuiPaginationItem-previousNext:disabled svg {
  stroke: #76787d;
}
@media (min-width: 820px) {
  .paging-option {
    margin: 0 auto;
  }
}
.MuiTab-root.Mui-selected {
  background-color: #f7f7fa;
  border-radius: 12px;
  font-weight: 600;
  font-size: 16px;
}
.MuiTabs-indicator {
  display: none;
}
.btn-gradient {
  border: none!important;
  background: linear-gradient(
      292.63deg,
      rgba(17, 34, 185, 0.74) 27.94%,
      #ff714f 103.8%
    ),
    #5161f1;
  border-radius: 12px !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-left: 0;
  border-right: 0;
  border-image-source: linear-gradient(
    292.6deg,
    rgba(17, 34, 185, 0.74) 28.37%,
    #ff714f 143.41%
  );
  color: #ffffff !important;
}
button.btn-gradient:focus {
  outline: none;
}
button.MuiButton-root.btn-disabled{
  border: none;
  background: grey;
  border-radius: 12px;
  color: #ffffff;
}
button.MuiButton-root.btn-disabled:hover{
  background-color: grey;
  color: #ffffff;
  border: none;
}
button.btn-disabled{
  border: none;
  background: grey;
  border-radius: 12px;
  color: #ffffff;
  padding-left: 28px;
  padding-right: 28px;
}
button.btn-cancel {
  background-color: #ffffff;
  border: 2px solid #5161F1;
  border-radius: 12px;
  color: #5161F1;
  font-weight: 600;
}
button.btn-cancel:hover {
  background-color: #ffffff;
  border: 2px solid #5161F1;
  color: #5161F1;
}
button.btn-cancel:focus {
  outline: none;
  border: 2px solid #5161F1;
}
button.btn-cancel:focus{
  outline: none;
}
button.btn-back-order {
  background-color: #E7EDFF;
  border: 2px solid #5080ff;
  border-radius: 12px;
  color: #5080ff;
  font-weight: 600;
  text-transform: none;
}
button.btn-trash {
    background-color: #FFFFFF;
    border: 2px solid #DF3731;
    border-radius: 12px;
    color: #DF3731;
    font-weight: 600;
}
button.btn-add {
  background-color: #3237bb;
  border: 2px solid #f9f3f3;
  border-radius: 12px;
  color: #f4ebeb;
  font-weight: 600;
}
button.btn-add:hover{
  background-color: #3237bb;
  border: 2px solid #f9f3f3;
}
button.btn-trash:hover{
  background-color: #FFFFFF;
  border: 2px solid #DF3731;
}
.btn-pause {
  background: linear-gradient(0deg, #DF3731, #DF3731), #ffffff;
  border-radius: 12px;
  border-radius: 12px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}
.btn-delete {
  background: #df3731 !important;
  border: 2px solid #df3731 !important;
  border-radius: 12px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
  
}
.btn-resume {
  background: linear-gradient(0deg, #5080ff, #5080ff), #ffffff;
  border-radius: 12px;
  border: 2px solid #5080ff !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}
.icon-action {
  stroke: #5161f1;
}
.icon-action svg {
  stroke: #5161f1;
  color: #5161f1;
  width: 1.25em;
  height: 1.25em;
}
.icon-edit-noti svg {
    stroke: #5161F1;
    color: #5161F1;
    width: 1em;
    height: 1em;
}

.icon-push-noti svg {
    stroke: #5161F1;
    color: #5161F1;
    width: 1em;
    height: 1em;
}
.icon-action.icon-delete svg {
  stroke: #df3731;
}
.icon-export-grey {
  stroke: #a9a9a9;
}
.icon-export-grey svg {
  stroke: #a9a9a9;
  color: #a9a9a9;
}
.action-button {
  background-color: #f7f7fa;
  border-radius: 50%;
  svg{
    width: 1em;
    height: 1em;
  }
}
.form-group input,textarea{
  border-radius: 12px !important;
}
.btn-back {
  border: 2px solid #5161f1 !important;
  border-radius: 12px !important;
  color: #5161f1 !important;
}
.MuiFormControl-root label {
  font-weight: 600;
}
.MuiCheckbox-root.Mui-checked {
  color: #5161f1 !important;
}
.MuiCheckbox-root.Mui-checked.disbale-campaign-notification {
  color: #ccc !important;
}
.bd-8 {
  border-radius: 8px !important;
}
.bd-12 {
  border-radius: 12px !important;
}
.dynamic-placeholder {
  position: relative;
  top: -40px;
  font-size: 12px !important;
  right: -12px;
  width: 110px;
}
.dynamic-placeholder.detail-page {
  font-size: 14px !important;
  line-height: 21px;
  width: auto !important;
}

input.tx-dynamic-placeholder:focus + .dynamic-placeholder,
input.tx-dynamic-placeholder:not(:placeholder-shown) + .dynamic-placeholder,
.dynamic-placeholder.on-top {
  top: -56px;
  color: #5161F1;
}
.item-promotion textarea.tx-dynamic-placeholder:focus + .dynamic-placeholder,
.item-promotion textarea.tx-dynamic-placeholder:not(:placeholder-shown) + .dynamic-placeholder {
  color: #5161F1;
  top: 8px;
  left: 12px;
}

input.tx-dynamic-placeholder {
  font-size: 17px;
  padding-top: 25px;
  font-weight: bold;
  background-color: white !important;
}
.item-promotion textarea.tx-dynamic-placeholder {
  font-size: 17px;
  font-weight: bold;
  background-color: white !important;
  border: 0;
  border-top: 33px solid white !important;
}

textarea.tx-dynamic-placeholder:disabled{
  border-top-color: #EBEBEB !important;
}

input[type="datetime-local"] {
  font-size: 17px;
  padding-top: 30px;
  padding-bottom: 8px;
  font-weight: bold;
  background-color: white !important;
}

textarea.tx-dynamic-placeholder:disabled{
  background-color: #EBEBEB !important;
}
.item-promotion textarea.tx-dynamic-placeholder:disabled{
  cursor: default;
}
.item-campaign input.tx-dynamic-placeholder:disabled {
  background-color: #EEEEEE !important;
}

input.tx-dynamic-placeholder:disabled{
  background-color: #EBEBEB !important;
}

input.tx-dynamic-placeholder:disabled + .dynamic-placeholder,
textarea.tx-dynamic-placeholder:disabled + .dynamic-placeholder {
  width: 140px;

}

input.tx-dynamic-placeholder:not(:placeholder-shown):disabled
  + .dynamic-placeholder,
textarea.tx-dynamic-placeholder:not(:placeholder-shown):disabled
  + .dynamic-placeholder {
  color: #76787d;
}

input.tx-dynamic-placeholder::placeholder,
textarea.tx-dynamic-placeholder::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  opacity: 0;
}

input.tx-dynamic-placeholder:-ms-input-placeholder,
textarea.tx-dynamic-placeholder:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0;
}

input.tx-dynamic-placeholder::-ms-input-placeholder,
textarea.tx-dynamic-placeholder::-ms-input-placeholder {
  /* Microsoft Edge */
  opacity: 0;
}

@media (min-width: 1280px) {
  .container {
    max-width: 1920px !important;
  }
}

header {
  position: relative;
  z-index: 99999999999999;
}
.w-full.bg-gray {
  padding-top: 25px;
  top: -25px;
  position: relative;
}
.h-screen {
  height: 86.5vh !important;
}
.color-range-placeholder {
  top: 5px;
  left: 3px;
}

.other-variants li {
  border-bottom: 1px solid #ccc;
}

.other-variants li:first-child {
  border-top: 1px solid #ccc;
}

.other-variants {
  padding-top: 20px !important;
}
@media (max-width: 1024px) {
  .noti-mobile-dpn {
    display: none !important;
  }
}
@media (max-width: 768px) {
  .mobile-dpn {
    display: none !important;
  }
  .mb-pl0 {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  .paging-custom {
    margin: auto;
  }
  .mb-keep-all {
    word-break: keep-all;
  }
}

.common-modal-footer {
  background-color: white !important;
  padding-left: 3.5rem !important;
  padding-top: 0rem !important;
  padding-bottom: 2rem !important;
}

.customer-detail-title {
  font-size: 26px;
  font-weight: 600;
}

.Toastify__progress-bar--success {
  background-color: #3f83f8 !important;
}
.Toastify__toast--success svg {
  fill: #3f83f8 !important;
}

.order-list-page .export-button {
  font-size: 17px;
  font-weight: 600;
  color: #5161f1;
  border-color: #5161f1;
  border-width: 2px;
  border-style: double;
  border-radius: 12px;
  @media (max-width: 768px) {
    min-width: 320px;
  }
}

.px-1-i {
  padding-left: 0.25rem !important;
  padding-right: 0.5rem !important;
}

@media (min-width: 1024px) {
  .desktop-dpn {
    display: none !important;
  }
}
.hover-border-blue:hover {
  border-color: #5161f1;
}
.paging-client > ul > li:not(:first-child, :last-child) > .Mui-selected {
  background-color: #5161f1;
}
.w100 {
  width: 100px;
}
.address-form .dynamic-placeholder {
  font-size: 16px !important;
  line-height: 18px !important;
}
.address-form input.tx-dynamic-placeholder:focus + .dynamic-placeholder,
.address-form
  input.tx-dynamic-placeholder:not(:placeholder-shown)
  + .dynamic-placeholder,
.address-form .dynamic-placeholder.on-top {
  font-size: 12px !important;
}
@media (max-width: 768px) {
  .csv-name{
    max-width: 120px;
  }
  .csv-result-icon{
    min-height: 64px;
  }
}
@media (min-width: 768px) {
  .csv-name{
    max-width: 588px;
  }
  .csv-result-icon{
    min-height: 96px;
  }
}
.hideprice-select{
  width: 140px !important;
}
.hideprice-select .MuiSelect-select{
  left: 0px;
  padding-left: 15px !important;
  position: absolute !important;
  width: 125px !important;
}
.h-15 {
    height: 56px;
}

@media (min-width: 1468px) {
    div {
        .xxl\:col-span-1

{
    grid-column: span 1 / span 1;
}

.xxl\:col-span-2 {
    grid-column: span 2 / span 2;
}

.xxl\:col-span-3 {
    grid-column: span 3 / span 3;
}

.xxl\:col-span-4 {
    grid-column: span 4 / span 4;
}

.xxl\:col-span-5 {
    grid-column: span 5 / span 5;
}

.xxl\:col-span-6 {
    grid-column: span 6 / span 6;
}

.xxl\:col-span-7 {
    grid-column: span 7 / span 7;
}

.xxl\:col-span-8 {
    grid-column: span 8 / span 8;
}

.xxl\:col-span-9 {
    grid-column: span 9 / span 9;
}

.xxl\:col-span-10 {
    grid-column: span 10 / span 10;
}

.xxl\:col-span-11 {
    grid-column: span 11 / span 11;
}

.xxl\:col-span-12 {
    grid-column: span 12 / span 12;
}

}
}

input[name=file-input]::placeholder {
    opacity: 1;
    color: #5161F1;
    font-weight: 600;
}
.action-container {
  @media only screen and (min-width: 600px) {
    float: right;
    margin-right: 5px;
  }
}
button.btn-action {
  color: #5161F1;
  background-color: #FFFFFF;
  border: 2px solid #5161F1;
  border-radius: 12px;
  float: right;
  padding: 10px;
  margin-left: 0;
}
button.btn-action:focus{
  color: #5161F1;
  background-color: #FFFFFF;
  border: 2px solid #5161F1;
  outline: none;
}
button.btn-action:hover{
  color: #5161F1;
  background-color: #FFFFFF;
  border: 2px solid #5161F1;
  outline: none;
}
button.btn-add-user {
    font-size: 17px;
    font-weight: 600;
    color: #5161F1;
    border-color: #5161F1;
    border-width: 2px;
    border-style: double;
    height: 48px;
    border-radius: 12px;
    margin-top: 5px;
    margin-left: 15px;

    @media only screen and (min-width: 600px) {
        margin-top: 0;
    }
}

.mandatory-checkbox svg {
    color: #FF714F;
}

.dialog-dynamic-choosen .MuiPaper-rounded {
    border-radius: 16px;
}

button.trash-button {
    font-size: 17px;
    font-weight: 600;
    color: #DF3731;
    border-color: #DF3731;
    border-width: 2px;
    border-style: double;
    height: 48px;
    border-radius: 12px;
}
button.trash-button:hover {
  color: #DF3731;
  border-color: #DF3731;
  border-width: 2px;
}
button.trash-button:focus{
  outline: none;
}
.tool-tip {
    border: 1px solid #FFDD94 !important;
    border-radius: 8px !important;
    color: black !important;
}
div.noti-tool-tip{
  max-width: 250px;
  border: 1px solid #FFDD94;
  border-radius: 8px;
  color: black;
  text-align: center;
  padding: 10px;
  white-space: initial;
}
div.noti-tool-tip.tooltip-span-icon{
  max-width: 220px;
  border: none;
}
div.noti-tool-tip.action-tooltip{
  color: white;
  border: none;
  background-color: #5161F1;
}
div.noti-tool-tip.action-tooltip-delete{
  color: white;
  border: none;
  background-color: #DF3731;
}
div.noti-tool-tip.include-tip{
  max-width: 190px;
}
div.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.box-complete{
  margin: 0 auto;
  background-color: #edf1de;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    stroke-width: 60px;
  }
  margin-top: 24px;
}
.box-logo{
  margin: 0 auto;
  background-color: #edf1de;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    stroke-width: 60px;
  }
}
.fixed_header {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.table-order-product{
  padding: 15px 0;
  max-height: 260px;
  overflow: hidden;
  max-height: 400px;
  border-width: 1px;
  border-radius: 16px;
  td{
    text-transform: none;
    padding: 0.5rem;
  }
  .product-table-header {
    left: 0;
    right: 0;
    padding: 3px;
    // position: sticky;
    top: 0;
  }
  .table-container>div{
    overflow-y: auto;
    max-height: 230px;
  }
  .table-container {
    border-radius: 0;
  }
  thead{
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
.table-flyers{
  max-height: 260px;
  overflow: hidden;
  max-height: 400px;
  td{
    text-transform: none;
    padding: 0.5rem;
  }
  .product-table-header {
    left: 0;
    right: 0;
    padding: 3px;
    // position: sticky;
    top: 0;
  }
  .table-container>div{
    overflow-y: auto;
    max-height: 350px;
  }
  thead{
    position: sticky;
    top: 0;
    z-index: 999;
  }
}
p.tooltip-custom:not(.w-full){
  width: fit-content;
  display: inline;
}
.tooltip-span-icon{
  color: white;
}
.tooltip-flyer-span-icon{
  color: black;
}
p.box-warning{
  margin: 0 auto;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-progressing{
  margin: 0 auto;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @keyframes circular-rotate {
    0% {
      transform: rotate(0deg);
      /* Fix IE11 wobbly */
      transform-origin: 50% 50%;
    }
    100% {
      transform: rotate(360deg);
    }
  }
  img{
    animation: circular-rotate 1.4s linear infinite;
    transform: rotate(360deg);
  }
}
.fixed_header tbody {
  display: block;
  width: 100%;
  overflow: auto;
  max-height: 170px;
}
.fixed_header thead tr {
  display: block;
}
.fixed_header th,
.fixed_header td {
  padding: 5px;
  text-align: left;
  width: 200px;
}
.notification-icon{
  width: 64px;
  height: 64px;
  @media (max-width: 768px) {
    width: 48px;
    height: 48px;
  }
}
div{
  a.button-download-icon:hover{
    background: none;
  }
}
button.MuiIconButton-edgeEnd{
  outline: none;
}
.MuiIconButton-edgeEnd:focus{
  outline: none;
  border: 0;
}
.export-pos-orders-desc{
  padding-top: 40px !important;
}
.MuiTabs-root{
  button.MuiTab-root:focus{
    outline: none;
    border: 0;
  }
  button.Mui-selected{
    color: #5161F1;
  }
}

.MuiTabs-root button.Mui-selected,
.MuiTabs-root a.Mui-selected {
  color: #5161F1 !important;
  border: none !important;
}

.MuiTabs-root button{
  @media (max-width: 768px) {
    border-radius: 12px;
    border: 1px solid white;
  }
  margin-right: 16px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.MuiTabScrollButton-root.Mui-disabled{
  display: none;
}
.filter-btn{
  height: 46px;
  border-radius:12px !important;
  border:2px solid #5161F1 !important;
  color:#5161F1 !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
}
.upload-file {
  width: 100%;
  height: 279px;
  grid-gap: 24px;
  gap: 24px;
  border-color: #76787d;
  cursor: pointer;
}

@media (max-width: 768px) {
  .pdf-name{
    max-width: 120px;
  }
}
@media (min-width: 768px) {
  .pdf-name{
    max-width: 20vw;
  }
}

.MuiFormLabel-asterisk{
  color:rgb(224, 36, 36);
}
.disabled-delete .icon-action svg {
  stroke: #76787D;
  color: #76787D;
}
.enabled-delete .icon-action svg {
  stroke: #DF3731;
  color: #DF3731;
}

.text-general-blue{
  color: #5161F1;
}
.text-general-blue span{
  color: #5161F1;
}
a.active-lib{
  color: #5161F1;
  border-left: 4px solid #5161F1;
}
.sub-tab-menu {
    .MuiBox-root {
        padding: 0;
    }

    border-radius: 12px 12px 0 0 !important;
}

.MuiIconButton-root:focus {
    outline: none;
}

.orders-time-picker{
  border-width: 1px !important;
  border-color: rgba(0, 0, 0, 0.23) !important;
  .ant-picker-focused{
      border-color: #5161F1 !important;
      border-width: 2px !important;
  }
  input{
    font-weight: 600 !important;
    opacity: 0.7;
  }
}
.orders-time-picker.ant-picker-focused{
  border-color: #5161F1 !important;
  border-width: 2px !important;
}
.ant-picker-dropdown{
  z-index: 999999;
}
li{
  margin-top: 8px !important;
  div.ant-picker-time-panel-cell-inner{
    margin: auto !important;
    padding: 4px !important;
    border: 2px solid #E3E4E4;
    border-radius: 6px !important;
    font-weight: 800;
    font-size: 14px;
    width: 32px !important;
    height: 32px !important;
    text-align: center !important;
    line-height: 20px !important;
  }
}
li.ant-picker-time-panel-cell-selected{
  div.ant-picker-time-panel-cell-inner{
    background-color: #5161F1 !important;
    border-color: #5161F1 !important;
    color: white !important;
  }
}
.ant-picker-suffix{
  cursor: pointer;
  svg{
    width: 20px;
    height: 20px;
    color: #121A1E;
  }
}
.ant-picker-clear{
  cursor: pointer;
  svg{
    width: 20px;
    height: 20px;
    color: #121A1E;
  }
}
.ant-picker-ok{
  background-color: #5161F1 !important;
  color: white !important;
  border-radius: 6px !important;
  font-weight: 800;
  font-size: 14px;
  button{
    background-color: #5161F1 !important;
    color: white !important;
    border: none !important;
  }
}
.ant-picker-time-panel-column{
  border: none !important;
}
.ant-picker-time-panel-column:first-child{
  li.ant-picker-time-panel-cell{
    padding-right: 0 !important;
    margin-right: 0 !important;
    div{
      margin-right: 3px !important;
    }
  }
}
.ant-picker-time-panel-column:last-child{
  li.ant-picker-time-panel-cell{
    padding-left: 0 !important;
    margin-left: 0 !important;
    div{
      margin-left: 3px !important;
    }
  }
}
.ant-picker-footer{
  display: none !important;
}
.ant-picker-input>input::placeholder {
  color: rgba(0, 0, 0, 0.87) !important;
}
.ant-picker-input>input{
  width: 70px !important;
}
.dialog-customer-style {
  border-radius: 16px !important;
  margin-left: 36px;
  button.PrivatePickersYear-yearButton {
    font-weight: 800;
    font-size: 14px;
    outline: none;
  }

  .PrivatePickersYear-yearButton.Mui-selected {
    background-color: #5161F1;
    color: white;
    border: none;
  }

  .PrivatePickersYear-yearButton.Mui-selected:hover {
    background-color: #5161F1;
    color: white;
    border: none;
  }
  .MuiPickersCalendarHeader-label {
    font-weight: 800;
  }

  .MuiDayPicker-header span {
    font-weight: 800;
    color: #121A1E;
    font-size: 14px;
  }

  .MuiDayPicker-weekContainer {
    button {
      margin: 4px;
      font-weight: 800;
      font-size: 14px;
      border-radius: 6px;
    }

    button.MuiPickersDay-today:not(.Mui-disabled) {
      background-color: #E7EDFF;
      color: #121A1E;
      border: none;
      outline: none;
    }
    button.MuiPickersDay-root{
      outline: none;
    }
    button.MuiPickersDay-root.Mui-selected {
      background-color: #5161F1;
      color: white;
      border: none !important;
    }
  }
  .MuiPickersArrowSwitcher-root{
    button.MuiPickersArrowSwitcher-button{
      box-shadow: 0 2px 2px #888888;
    }
  }
}
.ant-picker-time-panel-column::-webkit-scrollbar {
    display: none;
}

.ant-picker-time-panel-column {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.MuiDataGrid-row {
  div.MuiDataGrid-cell--withRenderer:focus {
    outline: none;
  }
}

.box-count-down {
    .MuiPaper-root {
        border-radius: 24px;
    }
}

div.update-modal {
    margin-left: 10px;
    margin-right: 15px;
}

.action-group {
  margin: 10px 0;
  background-color: #F7F7FA;
  border-radius: 32px;
}
.noti-title{
  word-break: break-all;
  min-width: 120px;
  max-width: 160px;
}
.no-mt{
  margin-top: 0px !important;
}

.calendar-dialog-custom-style {
    .MuiDayPicker-header {
      .MuiDayPicker-weekDayLabel {
        margin: 0 4px;
      }

    }
    .MuiDayPicker-monthContainer {
      .MuiPickersDay-root {
        margin: 0 4px;
      }
    }
    
}

span.badge-text {
    font-size: 16px;

    svg {
        margin: auto 2px;
    }
}
li.pop-menu{
  padding: 2px 10px;
}

button.btn-delete-noti {
  color: #DF3731;
  background-color: #FFFFFF;
  border: 2px solid #DF3731;
  border-radius: 12px;
  float: right;
  padding: 10px;
}

button.btn-delete-noti:hover {
  color: #DF3731;
  background-color: #FFFFFF;
  border: 2px solid #DF3731;
}

.opacity-full {
  opacity: 1 !important;
}

.MuiInputLabel-animated.MuiInputLabel-shrink{
  color: #5161F1 !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline{
  border-color: #5161F1 !important;
}
.footer-date-picker{
  background-color: white;
  border-radius: 12px;
  .MuiInputBase-root{
    height: 56px;
  }
  .MuiInputLabel-root{
    top: 3px;
  }
}
.MuiPickersPopper-root{
  z-index: 999999 !important;
}

.required{
  color: #FF714F;
}

.number-field{
  border: 1px solid #76787D;
  border-radius: 12px;
}
.number-field:focus{
  border: 1px solid #5161F1 !important;
  outline: 1px solid #5161F1 !important;
}
.no-shrink-label .MuiInputLabel-animated.MuiInputLabel-shrink{
  display: none;
}
.error-icon svg{
  width: 18px;
  height: 18px;
}
.no-py{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

span.MuiRadio-root:not(.Mui-disabled){
  color: #5161F1;
  span{
    color: #5161F1;
  }
}
label.fit-content{
  width: fit-content;
}

.data-picker-custom-container {
  position: relative;
  .date-text-input {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
    align-items: center;
    background-color: white;
    z-index: 9;
    opacity: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    .label-text {
      outline: none;
      color: #76787D;
      margin-right: 15px;
      &.text-hover {
        &:hover {
          color: #5161F1;
          cursor: pointer;
          text-decoration: underline;
        }
      }
      &.text-disabled {
        &:hover {
          color: #76787D;
          cursor: auto;
          text-decoration: none;
        }
      }
    }
    .label-button {
      outline: none;
      color: #5161F1;
    }
    &.date-text-required {
      .label-text, .label-button {
        color: #FF714F;
      }
      &:hover {
        cursor: pointer;
        .label-text, .label-button {
          color: #5161F1;
        }
      }
    }
  }
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
    fieldset {
      border-color:#fff !important;
    }

  }
  .date-picker-custom-link {
    .MuiInputBase-root.MuiOutlinedInput-root {
      width: 130px;
    }
  }
  .MuiFormControl-root.MuiTextField-root {
    input {
      padding: 0;
    }
    fieldset {
      border-color:#d5d6d7 !important;
    }
  }

}

.date-picker-clear-selection {
    position: absolute;
    z-index: 10;
    width: 100%;
    bottom: 0;
    left: 0;
    padding-bottom: 5px;
    height: 40px;
    justify-content: center;
    display: flex;
    .clearn-selection-button {
      padding: 0 10px;
      height: 26px;
      width: 80%;
      border-radius: 20px;
      color: #76787D;
      font-size: 14px;
      outline: none;
      &.clear-active {
        border: 1px solid #5161F1;
        color: #5161F1;
      }
    }
}

.errors-log-dialog{
  padding: 0 4rem 40px 4rem !important;
  @media (max-width: 768px) {
    padding: 0 2rem 40px 2rem !important;
  }
}

.table-sticky-header{
  overflow: hidden;
  max-height: 410px;
  .product-table-header {
    left: 0;
    right: 0;
    padding: 3px;
    // position: sticky;
    top: 0;
  }
  .table-container>div{
    overflow-y: auto;
    max-height: 310px;
  }
  thead{
    position: sticky;
    top: 0;
    z-index: 999;
  }
}

.beta-feature{
  background-color: #F4C762;
  border-radius: 30px;
  color: #121A1E;
  font-weight: 500;
  font-size: 12px;
  padding-top:2.5px;
  padding-bottom:2.5px;
  padding-left: 6px;
  padding-right: 6px;
  margin-right: 10px;
  margin-left: 10px;
  display: inline-block;
}

.pricing-plan-selection {
  .collapse-acccordion {
    .MuiMenuItem-root.MuiMenuItem-root {
      margin-top: 0 !important;
      padding: 10px 5px;
    }
  }

  &.plan-detail-selection {
    .MuiList-root.MuiMenu-list {
      padding-bottom: 0;
    }

  }
  .MuiPaper-root.MuiAccordion-root.collapse-acccordion {
    margin: 0 !important;
  }
  .MuiList-root{
    padding:0px;
  }
}

.pricing-plan-form {
  .pricing-plan-form-blank {
    background-color: white;
    position: absolute;
    left: 0;
    top: -2px;
    height: 160px;
    width: 115px;
    @media (min-width: 768px) {
      width: 144px;
    }
    &.banner {
      @media (max-width: 768px) {
        height: 220px;
      }
    }
  }
}
.thin-scrollbar {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    height: calc(100vh / 3.33);
  }
}

.help-page{
  overflow-y: hidden !important;
}
.disabled-text{
  color: transparent;
  text-shadow: 0 0 0 #5161F1;

  &:focus {
      outline: none;
  }
}
div{
  .chip-add{
    text-wrap: nowrap;
    text-transform: none !important;
    padding: 6px 10px;
    font-size: 13px;
    background-color: #FFF5E7 !important;
    stroke: #F4C762 !important;
    border-radius: 12px !important;
    color: black !important;
    border-width: 1px;
    border-color: #F4C762 !important;
    .plus-text{
        font-weight: 800;
    }
  }
}

button.h-54{
  height: 54px;
}
button.h-46{
  height: 46px;
}
button.h-48{
  height: 48px;
}
.py-3-i{
  padding-top: 0.5rem!important;
}
.max-w-2xl	{max-width: 42rem;}
.max-w-3xl	{max-width: 48rem;}
.max-w-4xl	{max-width: 56rem;}
.max-w-4-5xl	{max-width: 60rem;}
.max-w-5xl	{max-width: 64rem;}
.max-w-6xl	{max-width: 72rem;}
.max-w-7xl	{max-width: 80rem;}
.number-pattern{
  border: 1px solid red;
  padding: 4px;
  border-radius: 8px;
}
.number-pattern.has-value{
  border: 1px solid black;
  padding: 4px;
  border-radius: 8px;
}
.MuiSelect-select{
  .MuiTypography-caption{
    color: #93959A;
  }
}
.box-count-product{
  height: calc(50vh - 40px);
}
.box-count-product-2{
  height: calc(55vh - 20px);
}
.red-border-btn{
  color: #DF3731 !important;
  border-color: #DF3731 !important;
}
.MuiPaper-root.MuiMenu-paper{
  padding: 10px !important;
}
@media (min-width: 1024px) and (max-width: 1660px) {
  .main-wrapper-header, .main-wrapper-sidebar,.main-wrapper-body, .MuiDialog-container, .MuiPickersPopper-root, .ant-picker-dropdown, .common-modal, .tooltip-zoom{
    zoom: .8;
  }
  .MuiPopover-root, .MuiBox-root{
    .MuiPaper-root{
      padding: 0;
    }
  }
  .enable-zoom{
    .MuiPaper-root, .MuiAutocomplete-popper{
      zoom: .8;
      padding: 20px;
    }
  }
  .invoice-datepicker-popper{
    margin-bottom: 20vh;
  }
}
.holiday-container .MuiPaper-root{
  position: sticky;
}
.custom-switch-checked {
  .Mui-checked {
    color: #5161F1 !important
  }
  .MuiSwitch-track{
    background-color: rgba(81, 97, 241, 0.3) !important;
  }
}
.custom-switch {
  .MuiSwitch-switchBase {
    color: #93959A !important
  }
  .MuiSwitch-track{
    background-color: rgba(0, 0, 0, 0.3) !important;
  }
}
div {
  .chip-add {
      text-wrap: nowrap;
      text-transform: none !important;
      padding: 6px 10px;
      font-size: 13px;
      background-color: #FFF5E7 !important;
      stroke: #F4C762 !important;
      border-radius: 12px !important;
      color: black !important;
      border-width: 1px;
      border-color: #F4C762 !important;

      .plus-text {
          font-weight: 800;
      }
    }
    .chip-add:focus {
      outline: none;
    }
    .chip-add-segment {
        text-wrap: nowrap;
        text-transform: none;
        padding: 6px 10px;
        font-size: 14px;
        background-color: #F5F7FE;
        stroke: #5161F1;
        border-radius: 12px;
        color: #5161F1;
        border-width: 1px;
        border-color: #5161F1;
        font-weight: 400;
    }
}
.text-grey {
    color: #76787d;
}
.short-text-150{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 150px;
}
.short-text-200{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.outline-none {
  outline: none !important;
}

.text-input-customer-search {
  input::placeholder {
    color: #76787D;
    opacity: 1;
  }
}
