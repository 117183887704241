/* TailwindCss */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: "Noto Sans JP", system-ui, sans-serif;
    }
}

@layer components {
  .primary-btn {
    @apply w-full px-6 rounded-xl cursor-pointer;
    background: linear-gradient(
        292.63deg,
        rgba(17, 34, 185, 0.74) 27.94%,
        #ff714f 103.8%
      ),
      linear-gradient(0deg, #5080ff, #5080ff);
  }
	@media (min-width: 768px) {
		.primary-btn {
			@apply w-auto;
		}
	}

  .primary-btn > span {
    @apply font-semibold text-17 leading-22 text-white;
  }
}
